<template>
  <div class="program-study">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'program.studi.title' | text }}</h2>
          <p>{{ 'program.studi.desc' | text }}</p>
          <v-alert
            dismissible
            type="error"
            class="mt-4"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item,i) in listProgramStudy"
              :key="i"
            >
              <v-expansion-panel-header>
                <div class="program-study__content-title">
                  {{ `program.studi.${item.program_study}.title` | text}}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-alert
                  v-if="item.note"
                  dense
                  outlined
                  border="left"
                  type="info"
                >
                  {{ item.note }}
                </v-alert>
                <v-row no-gutters>
                  <v-col
                    cols=12
                    sm="12"
                    md="9"
                    lg="9"
                  >
                    <p>{{ item.content }}</p>
                  </v-col>
                   <v-col
                    cols=12
                    sm="12"
                    md="3"
                    lg="3"
                    class="d-flex justify-end"
                  >
                    <v-btn 
                      color="primary"
                      :min-width="160"
                      :disabled="item.is_disabled"
                      @click="openDetail(item)"
                    >
                      PILIH PROGRAM
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
// Libs 
import { get } from 'dot-prop'

// Utils
import request from '@/utils/request'
import loadscreen from '@/utils/common/loadScreen'

// Constants
import {
  API_FORM_PROGRAM_STUDY,
} from '@/constants/apis'
import {
  REGISTRATION_STATUS
} from '@/constants/pages'

  export default {
    name: 'ProgramStudy',
    components: {
    },
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/program-studi', id: 'canonical' },
      ]
    },
    data () {
      return {
        alertMsg: '',
        isShowAlert: false,
        listProgramStudy: []
      }
    },
    computed: {
      items () {
        // is_disabled: true,
        // program_study: "mdiv",
        // note: '',
        // content: 'Program Studi Magister Divinitas (M.Div.) merupakan program studi teologi yang ditujukan kepada para lulusan sarjana/S1 umum yang terpanggil  menjadi rohaniwan penuh waktu.'
        
        return [{
            is_disabled: true,
            program_study: "sth",
            note: '',
            content: 'Program Sarjana Teologi (S.Th.) merupakan program studi teologi yang ditujukan kepada para lulusan SMA atau yang sederajat yang terpanggil menjadi rohaniwan penuh waktu.'
          }, {
            is_disabled: true,
            program_study: "mmin",
            note: '',
            content: 'Program Studi Magister Ministri (M.Min.) merupakan program studi yang ditujukan kepada orang Kristen (lulusan minimal S1) yang ingin diperlengkapi dengan pemahaman teologi yang kuat dalam melaksanakan pelayanan secara luas di tengah masyarakat dan gereja.'
          }, {
            is_disabled: true,
            program_study: "mth",
            note: '',
            content: 'Program Studi Magister Teologi (M.Th.) merupakan program studi yang ditujukan kepada para rohaniwan yang terpanggil untuk mendalami lebih lanjut studi teologi yang sesuai dengan konteks pelayanan mereka, baik di gereja, organisasi Kristen, maupun seminari.'
          }, {
            is_disabled: true,
            program_study: "mthu",
            note: '',
            content: 'Program ini ditujukan kepada calon mahasiswa yang telah menyelesaikan studi S1 umum (non teologi) dan terpanggil untuk melayani sebagai rohaniwan/Hamba Tuhan penuh waktu baik di gereja maupun di lembaga pelayanan Kristen.'
          }, {
            is_disabled: true,
            program_study: "sert",
            note: '',
            content: 'Program Sertifikat Teologi adalah program non gelar yang ditujukan kepada orang Kristen (minimal lulusan SMA) yang tidak bermaksud untuk menjadi rohaniwan penuh tetapi ingin mempelajari studi teologi dan diperlengkapi untuk melayani.'
          }
        ]
      },
    },
    async created() {
      loadscreen.show()
      this.isShowAlert = false
      const res = await request(API_FORM_PROGRAM_STUDY, {}, {methods: 'GET'})
      if (res.success && res.list) {
        // need to remove 
        this.listProgramStudy = res.list.filter(itm => itm.program_study !== 'mdiv')
        this.listProgramStudy.map(itm => {
          const contentData = this.items.filter(item => item.program_study === itm.program_study)
          if (contentData[0]) {
            itm.content = contentData[0].content
          }
          // TO DO: delete temporary disabled MDIV
          if (itm.program_study === 'mdiv') {
            itm.is_disabled = true
          }
        })
      } else {
        this.listProgramStudy = this.items
        this.alertMsg = get(res, 'resultInfo.resultMsg', 'Error System')
        this.isShowAlert = true
      }
      loadscreen.hide()
    },
    methods: {
      openDetail (item) {
        const params = {
          program_study: item.program_study,
        }
        this.$store.dispatch('getCompletionForm', params).then(res => {
          if (res.success) {
            this.$router.push({
              name: REGISTRATION_STATUS
            }).catch(() => {})
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', 'Error System')
            this.isShowAlert = true
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.program-study {
  &__content-title {
    font-weight: bold;
  }
}
</style>