import { render, staticRenderFns } from "./programStudy.vue?vue&type=template&id=1947455f&scoped=true&"
import script from "./programStudy.vue?vue&type=script&lang=js&"
export * from "./programStudy.vue?vue&type=script&lang=js&"
import style0 from "./programStudy.vue?vue&type=style&index=0&id=1947455f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1947455f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCol,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRow})
